@import './styles/colors.module';
@import './styles/breakpoints.module';

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > * {
    flex: 0 0 calc(50% - 10px);
    @media (max-width: $sm) {
      flex: 0 0 100%;
    }
  }


}
