@import './styles/colors.module';
@import './styles/breakpoints.module';

.box {
  background-color: white;
  padding: 15px 20px;
  transition: box-shadow 200ms ease, transform 200ms ease;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(194 194 194 / 50%);
}

.animated:hover {
  transform: translateY(-3px);
  box-shadow: 0 2px 4px 0 rgb(194 194 194 / 70%);
}
