@import './styles/colors.module';
@import './styles/breakpoints.module';

.box {
  padding: 0;
  height: 100%;

  &:hover {
    & .image {
      transform: scale(0.95, 0.95);
    }
  }
}

.image {
  height: 250px;
  transition: transform 200ms ease;
  transform-origin: top center;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.title {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  font-size: 18px;
  text-align: center;
}
