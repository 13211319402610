@import './styles/colors.module';
@import './styles/breakpoints.module';

.title {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1;
  color: $purple;
  padding: 30px 0;
}

.h1 {
  font-size: 40px;
}

.h2 {
  font-size: 35px;
}

.h3 {
  font-weight: 300;
  font-size: 30px;
}

